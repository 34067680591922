import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const ArticleFour = () => {
    useEffect(()=>{
        document.title="DataMapper - GIS Portal Enhances Collaboration for UK Nuclear Power Station"
        let descMeta=document.querySelector("meta[name='description']")
        descMeta.setAttribute("content", 'EDF, a French electric utility company, has partnered with Esri UK to implement an enterprise GIS for Hinkley Point C (HPC) nuclear power station.')
    },[])
  return (
    <div>
      <Header />
        <div className=''>
          <section className="ud-hero" id="home">
            <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                      <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
                        <h1 className="ud-hero-title pb-5">
                            GIS Portal Enhances Collaboration for UK Nuclear Power Station
                        </h1>
                      </div>
                  </div>
                </div>
            </div>
        </section>
        <div className='container m-5 py-5 article-view'>
          <p className="lead">
            EDF, a French electric utility company, has partnered with Esri UK to implement an enterprise GIS for Hinkley Point C 
            (HPC) nuclear power station, one of the largest and most complex construction projects in Europe. The GIS portal has 
            enabled better collaboration and streamlined digital workflows by providing a central source of all spatial data, maps, 
            apps and dashboards for over 1,500 users including employees and contractors. <br /><br />

            The GIS portal provides an interactive primary site map with over 100 different data layers, from BIM models and CAD data
            to construction operations, temporary works, utilities, logistics and emergency preparedness. According to Jon Dolphin, 
            construction project manager and GIS lead at Hinkley Point C, the clarity of construction data is critical to the success
            of the project, and the GIS portal provides a single view of the data, driving collaboration among the teams.
          </p> <br /><br />
          <h3 className="fw-bold">Digital workflows and real-time data</h3>
          <p className="lead">
            Esri's GIS solution has replaced manual methods using paper checklists, clipboards, and spreadsheets for collecting data 
            out in the field, with new digital workflows that are enhancing productivity and safety on site. Emergency planning data 
            is now collected on tablets and appears in real-time in the GIS portal. Temporary Works teams are using similar new digital
            workflows for planning and inspecting hundreds of simultaneous works, including excavations, earth works, scaffolding, 
            ramps or form work for reinforced concrete pours. <br /><br />

            Mobile GIS is being used to manage generator permit requests, allowing contractors to use a mobile app instead of a 
            spreadsheet to request a generator, which is then submitted into a workflow for approval. A dashboard displays the data
            so electrical and environmental teams can optimize the use of generators and reduce emissions across the site. The
            portal will see another increase in usage when the MEH phase (Mechanical, Electrical and HVAC) ramps up in 2023, to
            manage permitting and controlling what is being built on site. <br /><br />

            The use of Esri GIS has revolutionized the management of the Hinkley Point C project, providing a single source of
            spatial data that has improved collaboration, efficiency and safety, and could serve as a model for other complex
            engineering projects in the future.
          </p>
        </div>
        </div>
        <Footer />
    </div>
  )
}

export default ArticleFour
