import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const ArticleTwo = () => {
  useEffect(()=>{
    document.title="DataMapper - VMware Unveils Cross Cloud Managed Services"
    let descMeta=document.querySelector("meta[name='description']")
    descMeta.setAttribute("content", 'VMware has launched VMware Cross-Cloud managed services, a set of prescriptive offers with enhanced partner and customer benefits that will enable highly skilled partners to expand their managed services practices.')
    },[])
  return (
    <div>
        <Header />
        <div className=''>
          <section className="ud-hero" id="home">
            <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                      <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
                        <h1 className="ud-hero-title pb-5">
                            VMware Unveils Cross Cloud Managed Services
                        </h1>
                      </div>
                  </div>
                </div>
            </div>
        </section>
        <div className='container m-5 py-5 article-view'>
          <p className="lead">
            VMware has launched VMware Cross-Cloud managed services, a set of prescriptive offers with enhanced partner and customer
            benefits that will enable highly skilled partners to expand their managed services practices. <br /><br />

            Building on VMware’s success in creating an ecosystem of more than 4,000 cloud service providers that serve tens of 
            thousands of customers worldwide, VMware Cross-Cloud managed services will make building managed services faster for 
            partners and easier to consume by customers. This will improve partner profitability while opening new opportunities 
            for growth and expansion. <br /><br />

            The adoption of multi-cloud is helping customers become digitally smart. Multi-cloud services enable faster development
            of modern apps, accelerate enterprise cloud transformation, and empower more secure hybrid workforces. However, S&P 
            Global Market Intelligence research shows that ensuring security, having skilled personnel, managing costs, and
            workload/data mobility are the biggest challenges facing organisations adopting a multi-cloud operating model. 83% 
            of organisations surveyed are open to using external managed or professional services to support their hybrid and 
            multi-cloud efforts. <br /><br />

            With VMware Cross-Cloud managed services, VMware is making it easier for partners to do business with VMware by 
            combining prescriptive solutions, improved incentives, aligned go to market activities, and more flexibility. 
            Together, these will enable VMware Cross-Cloud Managed Services Providers to drive growth, improve profitability, 
            and increase recurring services revenue while improving time to value for multi-cloud environments and bridging 
            today’s IT talent and skills gap for customers. <br /><br />

            “With our global ecosystem of VMware Cross-Cloud Managed Services Providers, we will help customers to evolve from 
            cloud chaos to cloud smart while achieving outcomes faster,” said Zia Yusuf, senior vice president, strategic ecosystem
            and industry solutions, VMware. “VMware Cross-Cloud managed services is yet another example of how we will empower
            partners to expand and grow their VMware businesses while collaborating to help customers implement a cloud smart
            strategy that accelerates their digital transformation.” <br /><br />
          </p> <br /><br />
          <h3 className="fw-bold">Introducing VMware Cross-Cloud Managed Services Offers</h3>
          <p className="lead">
          VMware will deliver prescriptive offers targeting key customer outcomes and supported through close alignment with 
          VMware’s go-to-market, sales, and support motions. Partners will then use these targeted offers to build unique 
          validated Service Offerings to deliver differentiated services. <br /><br />
          </p>
          <ul>
            <li className="lead">
                - VMware Cross-Cloud managed services for Hybrid Cloud will enable customers to lower total cost of ownership (TCO) 
                for on-premises environments, simplify operations, reduce risk with observability, actionable insight, and performance 
                optimisation, and accelerate cloud migrations. These offers will include, (i) VMware Cross-Cloud managed services for
                private or sovereign cloud, and (ii) VMware Cross-Cloud managed services for public cloud. Partners will build these 
                services using VMware Cloud on AWS or new VMware Cloud Packs, which will include hyperconverged infrastructure,
                hyperconverged infrastructure with advanced automation, compute with advanced automation, and VMware Cloud Foundation.
            </li>
            <li className="lead">
                - VMware Cross-Cloud managed services for Native Public Cloud and Modern Apps will enable customers to automate more 
                secure modern platform operations across multiple clouds, achieve centralised governance and deliver better cost 
                controls. These offers will include, (i) VMware Cross-Cloud managed services for Centralised Governance powered by 
                VMware Aria; (ii) VMware Cross-Cloud managed services for Cost Optimisation powered by VMware Aria Cost; and (iii) 
                VMware Cross-Cloud managed services for Cloud Native App Delivery powered by VMware Tanzu.
            </li>
          </ul> <br /><br />
          <h3 className="fw-bold">Benefits, Incentives, and Capability Advancements for Cross-Cloud Managed Services Providers</h3>
          <p className="lead">
            Partners can differentiate themselves and command greater market recognition and profitability. The new VMware Cross-Cloud
            managed services badge will signify partners’ expertise in delivering VMware validated managed services offerings (VSOs) 
            and validate the achievement of the forthcoming VMware Managed Services Specialisation (MSS). Additionally, VMware 
            Cross-Cloud Managed Services Providers will benefit from tailored financial and non-financial partner benefits including: <br />
          </p>
          <ul>
            <li className="lead">
                - Improved Incentives: badged partners can potentially earn up to 40 percent more rewards for providing assessments and
                proofs-of-concept, up to 100 percent more than non-badged partners for deployment services, and significantly more 
                back-end rebates for selling VMware Cross-Cloud managed services through the new Sell incentive in Partner Connect.
            </li>
            <li className="lead">
                - More Flexibility: a new Bring Your Own Subscription option will allow customers to deploy existing VMware subscriptions
                to VMware Cross-Cloud Managed Services Provider environments, enabling them to derive maximum value for the 
                subscriptions through the support and expertise of qualified VMware partners.
            </li>
            <li className="lead">
                - Go To Market Alignment: a new co-selling program will enable VMware to support Cross-Cloud Managed Services Providers in demand 
                generation, as well as selling and closing activities.
            </li>
            <li className="lead">
                - Ease of Doing Business: partners can expect even more deal protection on SaaS related sales.
            </li>
          </ul> <br /><br />
          <p className="lead">
            Colin Bannon, CTO at Business, BT, said: “VMware Cross-Cloud services are an excellent fit for BT’s own next generation of cloud solutions,
            delivered at the near edge. <br /><br />

            “As a VMware Cross-Cloud Managed Services Provider we will continue building on strong foundations of collaboration and
            shared vision. Together, we will help customers innovate, address data sovereignty requirements, and reduce operational
            costs while focusing on sustainability.” <br /><br />

            Anand Swamy, executive VP, head of Tech and Telco Ecosystems, HCLTech.“VMware Cross-Cloud managed services, a core 
            component of HCLTech’s CloudSMART for VMware offerings, will help enterprises unlock the untapped value of multi-cloud 
            and app modernisation. <br /><br />

            “These new managed services offerings will further enable HCLTech to help enterprises pursue the path of digital 
            dominance by aligning transformation with overall business objectives while remaining agile through cloud freedom 
            and enterprise control.” <br /><br />

            Ofir Abekasis, CEO, TeraSky, said: “TeraSky helps organisations tackle the complexity of new technologies so they can 
            transform with confidence. VMware Cross-Cloud managed services are perfectly aligned to this mission. <br /><br />

            “With VMware Cross-Cloud managed services, TeraSky will help our customers meet their technical, operational, and 
            financial targets, address their IT skills gaps, and maximise investments in VMware technology. Achieving the VMware
            Managed Services Specialisation and becoming a VMware Cross-Cloud Managed Service Provider will improve our own 
            competitive differentiation and help fuel our growth.”
          </p>
        </div>
        </div>
        <Footer />
    </div>
  )
}

export default ArticleTwo
