import React from 'react'

const Footer = () => {
  return (
    <footer className="ud-footer wow fadeInUp" data-wow-delay=".15s">
      <div className="ud-footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <ul className="ud-footer-bottom-left">
                <li>
                  &#169; DataMapper Ltd. All rights reserved.
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <p className="ud-footer-bottom-right text-muted">
                <ul className="ud-footer-bottom-right">
                  <li>
                    <a href="https://www.linkedin.com/company/datamapper" target="_blank" rel='noreferrer'>
                      <i className="lni lni-linkedin-original text-muted"></i>
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
