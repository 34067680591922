import React from 'react'
import Header from '../components/Header'
const NotFound = () => {
  return (
    <div>
      <Header />
      <section className="ud-hero" id="home">
        <div className="container">
            <div className="row">
            <div className="col-lg-12">
                <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
                <h1 className="ud-hero-title pb-5">
                404 Page Not Found
                </h1>
                </div>
            </div>
            </div>
        </div>
    </section>
      <section className="ud-404">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ud-404-wrapper">
                <div className="ud-404-content">
                  <h2 className="ud-404-title">We couldn't find that page.</h2>
                  <ul className="ud-404-links">
                    <li>
                      <a href="/">Return Home</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default NotFound
