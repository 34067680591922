import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const ArticleFive = () => {
    useEffect(()=>{
        document.title="DataMapper - Tibco scales data analytics and visualization with an eye on AI"
        let descMeta=document.querySelector("meta[name='description']")
        descMeta.setAttribute("content", 'Enterprise analytics vendor Tibco is updating its software portfolio today with a series of releases designed to help improve analysis and data visualization.')
    },[])
  return (
    <div>
      <Header />
        <div className=''>
          <section className="ud-hero" id="home">
            <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                      <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
                        <h1 className="ud-hero-title pb-5">
                            Tibco scales data analytics and visualization with an eye on AI
                        </h1>
                      </div>
                  </div>
                </div>
            </div>
        </section>
        <div className='container m-5 py-5 article-view'>
          <p className="lead">
            Enterprise analytics vendor Tibco is updating its software portfolio today with a series of releases designed to help 
            improve analysis and data visualization with enhanced scalability, data streaming and integration of artificial 
            intelligence (AI) capabilities. <br /><br />

            This includes an update to Spotfire 12.2 with enhanced data visualization features as well as new data functionality for 
            developers. Tibco acquired the original Spotfire technology in 2007 for $195 million. At the time, Spotfire was positioned
            as a business intelligence vendor, although the technology has steadily evolved to an AI powered data analytics and 
            visualization platform. <br /><br />

            The Tibco ModelOps was launched in July 2022 is now being more tightly integrated with Spotfire in a bid to enable data 
            scientists to build and deploy analytics workflows powered by AI models. Additionally, Tibco is updating its streaming 
            data technology with a feature the company refers to as ‘dynamic learning’ that enables data analytics operations on 
            streaming data. The Tibco platform is also going cloud-native, with the ability for organizations to deploy software on
            Kubernetes infrastructure. <br /><br />

            “We have deployments now of up to 100,000 people going into the web environment to interact with Spotfire analysis, and
            that can use up a lot of resources,” Michael O’ Connell, Tibco chief analytics officer told VentureBeat. “So, being 
            able to scale that out horizontally with Kubernetes is really a big advance there for us.”
          </p> <br /><br />
          <h3 className="fw-bold">Developers and data functions</h3>
          <p className="lead">
            With data analytics, business analysts have commonly been a core focus for Tibco. <br /><br />

            That said, O’Connell emphasized that Tibco is also interested in helping developers that are building out more 
            sophisticated operations and data science initiatives. In the Spotfire 12.2 update, Tibco is bringing forward the 
            concept of ‘mods’ as a way to bring extensibility to the Spotfire platform. With the new extensibility developers 
            can code in JavaScript inside of Spotfire to build visual analytics operations. <br /><br />

            Going a step further, the new data functions capabilities will allow developers to more easily take custom code and turn
            it into ‘point and click’ analytics operations for business analysts. <br /><br />

            The extensibility for data analytics and data visualization can go even further than custom coding due to the Spotfire 
            Cloud Actions capabilities. Cloud Actions provide integration points with operational systems such as databases and 
            enterprise applications to provide a bridge between data in the Tibco platform and external sources.
          </p> <br /><br />
          <h3 className="fw-bold">Dynamic learning comes to data streaming</h3>
          <p className="lead">
            Streaming data is increasingly critical for business operations and data analytics. <br /><br />

            A challenge is that streaming data often comes from an open source Apache Kafka source and must be loaded into a database
            before it can be used for analytics or machine learning (ML). <br /><br />

            With the new dynamic learning feature in Tibco’s streaming data technology, O’Connell said that organizations will now be 
            able to train ML models directly from the event stream. <br /><br />

            “In the case of dynamic learning, we’ve developed a set of methods where you can build and train any model directly on 
            the event stream without the intermediate step of writing the data to the database,” he said. <br /><br />

            One particular use case where dynamic learning is useful is in manufacturing. O’Connell said that with data coming from 
            the manufacturing floor in real time, being able to understand and react to what’s happening as it happens is critical.
          </p> <br /><br />
          <h3 className="fw-bold">The intersection of data science and ML can be found in ModelOps</h3>
          <p className="lead">
            The ModelOps capabilities first released on the Tibco platform in 2022 provide organizations with model management capabilities
            for data science and AI workflows. <br /><br />

            Now with Spotfire 12.2, ModelOps is being more tightly integrated, enabling what O’Connell referred to as a full data 
            science lifecycle. Organizations can now train an ML model with the Tibco data science product that can then be cataloged
            and managed inside of Tibco ModelOps. <br /><br />

            Finally, Tibco Spotfire users can find and use an ML model managed by ModelOps and use it for data visualization and
            analytics operations. <br /><br />

            Looking forward, Tibco will be integrating generative AI capabilities into its platforms. <br /><br />

            “We now are working with Microsoft and Open AI in training our own models,” said O’Connell. “We’re working with GPT 3.5.
            Turbo edition to create an interactive chat inside of Spotfire to make suggestions for people who are writing their own
            data functions.“
          </p>
        </div>
        </div>
        <Footer />
    </div>
  )
}

export default ArticleFive
