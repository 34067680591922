import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import articleFifteen from '../article-images/articleFifteen.jpg';
import articleSixteen from '../article-images/articleSixteen.jpg';
import articleSeventeen from '../article-images/articleSeventeen.jpg';
import { Link } from 'react-router-dom';

const Home = () => {
return (
  <div className="App">
    <Header />
    <section className="ud-hero" id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
              <h1 className="ud-hero-title">
                Visualize your world with data-driven insights
              </h1>
              <p className="ud-hero-desc">
                Unlock the Power of your Data with our Comprehensive Solutions for Visualizing, Managing, and Storing
                Geospatial
                and Other Complex Data.
              </p>
              <ul className="ud-hero-buttons">
                <li>
                  <a href="#contact" rel="nofollow noopener" className="ud-main-btn ud-white-btn">
                    Enquire Now
                  </a>
                </li>
                <li>
                  <a href="#about" rel="nofollow noopener" className="ud-main-btn ud-link-btn">
                    Learn More <i className="lni lni-arrow-right"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="ud-hero-brands-wrapper wow fadeInUp" data-wow-delay=".3s">
              <br />
            </div>
            <div className="ud-hero-image wow fadeInUp" data-wow-delay=".25s">
              <img src="assets/images/hero/hero-image.jpeg" alt="Geospatial Data Hero" />
              <img src="assets/images/hero/dotted-shape.svg" alt="Hero Abstract" className="shape shape-1" />
              <img src="assets/images/hero/dotted-shape.svg" alt="Secondary Abstract" className="shape shape-2" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="features" className="ud-features">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-section-title">
              <span>Features</span>
              <h2>Main Services</h2>
              <p>
                We offer a range of solutions, tailored to the needs of each individual client,
                powered by our AI driven development.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-sm-6">
            <div className="ud-single-feature wow fadeInUp" data-wow-delay=".1s">
              <div className="ud-feature-icon">
                <i className="lni lni-map"></i>
              </div>
              <div className="ud-feature-content">
                <h3 className="ud-feature-title">Geospatial Data Mapping</h3>
                <p className="ud-feature-desc">
                  Our GIS team can help to build custom solutions to visualize, store and manipulate
                  geospatial data to suit your business needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6">
            <div className="ud-single-feature wow fadeInUp" data-wow-delay=".15s">
              <div className="ud-feature-icon">
                <i className="lni lni-cloud-check"></i>
              </div>
              <div className="ud-feature-content">
                <h3 className="ud-feature-title">Cloud Support</h3>
                <p className="ud-feature-desc">
                  We operate our own cloud support services to help you choose the right provider for
                  your needs, and aid you with the process.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6">
            <div className="ud-single-feature wow fadeInUp" data-wow-delay=".2s">
              <div className="ud-feature-icon">
                <i className="lni lni-bar-chart"></i>
              </div>
              <div className="ud-feature-content">
                <h3 className="ud-feature-title">Visual Data Solutions</h3>
                <p className="ud-feature-desc">
                  Our team also offers a variety of visual data services that can be customized for your
                  business needs. Fully tailored to each client.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6">
            <div className="ud-single-feature wow fadeInUp" data-wow-delay=".25s">
              <div className="ud-feature-icon">
                <i className="lni lni-code"></i>
              </div>
              <div className="ud-feature-content">
                <h3 className="ud-feature-title">Programming Support</h3>
                <p className="ud-feature-desc">
                  We offer our clients full programming support with any existing technologies in use as well
                  as building custom solutions as needed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="about" className="ud-about">
      <div className="container">
        <div className="ud-about-wrapper wow fadeInUp" data-wow-delay=".2s">
          <div className="ud-about-content-wrapper">
            <div className="ud-about-content">
              <span className="tag">About Us</span>
              <h2>Dedicated team, powered by the latest technologies</h2>
              <p>
                We understand that in the modern world, it is increasingly important for businesses to
                integrate with newer technologies such as modern data management solutions for faster and more secure data
                operations, and to be able to provide their data in more visual formats for their clients.
                Our modern GIS team can also provide interactive mapping systems and WebGIS services, and more.
              </p>

              <p>
                That's why we provide comprehensive support to new and existing businesses in migrating to contemporary
                based data operations, as well as providing programming support in all aspects of their business, from
                data visualization to application maintenance. We offer remote solutions to our clients and provide
                high-quality tailored services.
              </p>
            </div>
          </div>
          <div className="ud-about-image">
            <img src="assets/images/about/about-banner.png" alt="Abstract about section" width="500" />
          </div>
        </div>
      </div>
    </section>

    <section id="services" className="ud-pricing">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-section-title mx-auto text-center">
              <span>Services</span>
              <h2>Our Popular Services</h2>
              <p>
                Take a look at some of our popular services, we offer range of tailored services as well as custom
                packages for those who need more specific options
              </p>
            </div>
          </div>
        </div>

        <div className="row g-0 align-items-center justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="ud-single-pricing first-item wow fadeInUp" data-wow-delay=".15s">
              <div className="ud-pricing-header">
                <a href="/cloud-operations" className='zoomOnHover'>
                  <h3>PRICE ON ENQUIRY</h3>
                  <h4>Cloud Operations</h4>
                </a>
              </div>
              <div className="ud-pricing-body">
                <ul>
                  <li>Data Management</li>
                  <li>Cloud Based Data Storage</li>
                  <li>Server &amp; NAS Management</li>
                  <li>Hyper-V / VM Support</li>
                  <li>Technical Remote Support</li>
                </ul>
              </div>
              <div className="ud-pricing-footer">
                <a href="#contact" className="ud-main-btn ud-border-btn">
                  Enquire Now
                </a> <br />
                <a href="/cloud-operations" rel="nofollow noopener" className="pt-4 text-black">
                  Learn More <i className="lni lni-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="ud-single-pricing active wow fadeInUp" data-wow-delay=".1s">
              <span className="ud-popular-tag">POPULAR</span>
              <div className="ud-pricing-header">
                <a href="/programming-support" className='zoomOnHover'>
                  <h3>PRICE ON ENQUIRY</h3>
                  <h4>Programming Support </h4>
                </a>
              </div>
              <div className="ud-pricing-body">
                <ul>
                  <li>UX &amp; UI Design</li>
                  <li>Application Development</li>
                  <li>Code Maintenance</li>
                  <li>Data Visualization</li>
                  <li>Database Administration</li>
                </ul>
              </div>
              <div className="ud-pricing-footer">
                <a href="#contact" className="ud-main-btn ud-white-btn">
                  Enquire Now
                </a> <br />
                <a href="/programming-support" rel="nofollow noopener" className="pt-4 text-white">
                  Learn More <i className="lni lni-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="ud-single-pricing last-item wow fadeInUp" data-wow-delay=".15s">
              <div className="ud-pricing-header">
                <a href="/gis" className='zoomOnHover'>
                  <h3>PRICE ON ENQUIRY</h3>
                  <h4>GeoSpatial Data (GIS)</h4>
                </a>
              </div>
              <div className="ud-pricing-body">
                <ul>
                  <li>Spatial Data Operations</li>
                  <li>Mapping Analysis</li>
                  <li>Bespoke Map Creating</li>
                  <li>Remote Sensing</li>
                  <li>WebGIS Development</li>
                </ul>
              </div>
              <div className="ud-pricing-footer">
                <a href="#contact" className="ud-main-btn ud-border-btn">
                  Enquire Now
                </a> <br />
                <a href="/gis" rel="nofollow noopener" className="pt-4 text-black">
                  Learn More <i className="lni lni-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="faq" className="ud-faq">
      <div className="shape">
        <img src="assets/images/faq/shape.svg" alt="Frequently Asked Questions shape" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-section-title text-center mx-auto">
              <span>FAQ</span>
              <h2>Any Questions?</h2>
              <p>
                Take a look at some commonly asked questions, or feel free to contact our team
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="ud-single-faq wow fadeInUp" data-wow-delay=".1s">
              <div className="accordion">
                <button className="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                  <span className="icon flex-shrink-0">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>What is GIS?</span>
                </button>
                <div id="collapseOne" className="accordion-collapse collapse">
                  <div className="ud-faq-body">
                    GIS stands for Geographic Information Systems. The main software package is called ArcGIS and is
                    supplied
                    by the Environmental Systems Research Institute (ESRI). A good place to start learning more about the
                    potential applications of GIS in demography and the social sciences is ESRI’s home page. In addition,
                    you can find a staggering amount of information about GIS on the Internet, including software training
                    and tutorials, data products, statistical techniques, and myriad areas of application (crime, health,
                    planning, etc.).
                  </div>
                </div>
              </div>
            </div>
            <div className="ud-single-faq wow fadeInUp" data-wow-delay=".15s">
              <div className="accordion">
                <button className="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                  <span className="icon flex-shrink-0">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>What are some common data management challenges?</span>
                </button>
                <div id="collapseTwo" className="accordion-collapse collapse">
                  <div className="ud-faq-body">
                    Common data management challenges include data quality issues, data silos, security concerns, and the
                    need to integrate data from multiple sources.
                  </div>
                </div>
              </div>
            </div>
            <div className="ud-single-faq wow fadeInUp" data-wow-delay=".2s">
              <div className="accordion">
                <button className="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                  <span className="icon flex-shrink-0">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>How is code maintained?</span>
                </button>
                <div id="collapseThree" className="accordion-collapse collapse">
                  <div className="ud-faq-body">
                    The code maintenance service consists of our developers analysing and optimizing your codebase, as
                    well
                    as implementing new systems when and where necessary. It also offers regular updates for critical
                    systems
                    to ensure security and consistency. Regular backups are also taken to ensure the security of your
                    business
                    operations.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ud-single-faq wow fadeInUp" data-wow-delay=".1s">
              <div className="accordion">
                <button className="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                  <span className="icon flex-shrink-0">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>What is Geospatial data?</span>
                </button>
                <div id="collapseFour" className="accordion-collapse collapse">
                  <div className="ud-faq-body">
                    Geospatial data store the geometric location of a particular feature or object (represented as either
                    a
                    point, a line, or an area), along with attribute information describing what those features represent.
                    Geospatial data may come from a variety of sources—individual, household, or institutional surveys;
                    population censuses, land use surveys, human infrastructure databases (streets, buildings, business
                    listings, tax records, etc.), satellite imagery, aerial photographs, and paper maps. It is commonly
                    reported that over 80% of all data are geospatial—that is, these data can be linked to features or
                    objects such as a street address, a census tract, a ZIP code, a school district, a police precinct,
                    a health service area, a county, a state, and/or a country.
                  </div>
                </div>
              </div>
            </div>
            <div className="ud-single-faq wow fadeInUp" data-wow-delay=".15s">
              <div className="accordion">
                <button className="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive">
                  <span className="icon flex-shrink-0">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>Why is data management important?</span>
                </button>
                <div id="collapseFive" className="accordion-collapse collapse">
                  <div className="ud-faq-body">
                    Effective data management ensures that data is accurate, secure, and accessible
                    when it is needed. It also helps organizations comply with legal and regulatory
                    requirements related to data privacy and security.
                  </div>
                </div>
              </div>
            </div>
            <div className="ud-single-faq wow fadeInUp" data-wow-delay=".2s">
              <div className="accordion">
                <button className="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix">
                  <span className="icon flex-shrink-0">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>What data visualization is offered?</span>
                </button>
                <div id="collapseSix" className="accordion-collapse collapse">
                  <div className="ud-faq-body">
                    Our team of developers provides our users with fully interactive and responsive visual displays of
                    their
                    data, in formats ranging from charts to tables. These visuals can be programmed into existing
                    applications
                    or developed standalone for other purposes. Designs can also be provided upon request if necessary.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="news" className="ud-news">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-section-title mx-auto text-center">
              <span>News</span>
              <h2>See the latest industry news</h2>
              <p>
                View our latest articles
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
              <Link to="/spatial-data-visualization-unlocking-insights-through-visual-representation" className='article_preview'>
              <img src={articleSeventeen} alt='Article Thumbnail' className='rounded-3' />
              <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                  <div className="ud-news-info">
                      <div className="ud-news-meta">
                          <h4>Spatial Data Visualization: Unlocking Insights through Visual Representation</h4>
                          <br />
                          <p>27/06/2023</p>
                      </div>
                  </div>
              </div>
              </Link>
          </div>
          <div className="col-lg-4 col-md-6">
              <Link to="/transforming-businesses-through-visual-insights" className='article_preview'>
              <img src={articleSixteen} alt='Article Thumbnail' className='rounded-3' />
              <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                  <div className="ud-news-info">
                      <div className="ud-news-meta">
                          <h4>Transforming Businesses through Visual Insights</h4>
                          <br />
                          <p>23/06/2023</p>
                      </div>
                  </div>
              </div>
              </Link>
          </div>
          <div className="col-lg-4 col-md-6">
              <Link to="/revolutionizing-data-management-in-gis-with-ai" className='article_preview'>
              <img src={articleFifteen} alt='Article Thumbnail' className='rounded-3' />
              <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                  <div className="ud-news-info">
                      <div className="ud-news-meta">
                          <h4>Revolutionizing Data Management in GIS with AI</h4>
                          <br />
                          <p>22/06/2023</p>
                      </div>
                  </div>
              </div>
              </Link>
          </div>
        </div>
      </div>
      <div className='text-center mb-5'>
      <a href="/news" className="ud-main-btn ud-border-btn">
          View More
      </a>
      </div>
    </section>

    <section id="contact" className="ud-contact">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-8 col-lg-7">
            <div className="ud-contact-content-wrapper">
              <div className="ud-contact-title">
                <span>CONTACT US</span>
                <h2>
                  Let’s get talking
                </h2>
              </div>
              <div className="ud-contact-info-wrapper">
                <div className="ud-single-info">
                  <div className="ud-info-icon">
                    <i className="lni lni-map-marker"></i>
                  </div>
                  <div className="ud-info-meta">
                    <h5>Our Location</h5>
                    <p>Greater Nowrich Area, Norfolk, UK</p>
                  </div>
                </div>
                <div className="ud-single-info">
                  <div className="ud-info-icon">
                    <i className="lni lni-envelope"></i>
                  </div>
                  <div className="ud-info-meta">
                    <h5>How Can We Help?</h5>
                    <p><a className="text-muted" href="mailto:contact@datamapper.com">contact@datamapper.uk</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="ud-contact-form-wrapper wow fadeInUp" data-wow-delay=".2s">
              <h3 className="ud-contact-form-title">Send us a Message</h3>
              <form className="ud-contact-form" action="https://formspree.io/f/xyyadwbd" method="post">
                <div className="ud-form-group">
                  <label for="fullName">Full Name*</label>
                  <input type="text" name="fullName" placeholder="John Doe" required />
                </div>
                <div className="ud-form-group">
                  <label for="email">Email*</label>
                  <input type="email" name="email" placeholder="example@yourmail.com" required />
                </div>
                <div className="ud-form-group">
                  <label for="phone">Phone*</label>
                  <input type="tel" name="phone" placeholder="01234 567 890" required />
                </div>
                <div className="ud-form-group">
                  <label for="message">Message*</label>
                  <textarea name="message" rows="2" placeholder="Type your message here" required></textarea>
                </div>
                <div className="ud-form-group mb-0">
                  <button type="submit" className="ud-main-btn">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
  )
}

export default Home