import React, { useEffect } from 'react'
import { HashLink } from 'react-router-hash-link';

(function () {
  window.onscroll = function () {
    const ud_header = document.querySelector(".ud-header");
    const sticky = ud_header.offsetTop;
    const logo = document.querySelector(".navbar-brand img");

    const dropdown = document.querySelector(".dropdown-target");

    if (window.pageYOffset > sticky) {
      ud_header.classList.add("sticky");
    } else {
      ud_header.classList.remove("sticky");
    }

    if (ud_header.classList.contains("sticky")) {
      logo.src = "assets/images/logo/logo.png";
      dropdown.classList.add("text-black");
      dropdown.classList.remove("text-white");
    } else {
      logo.src = "assets/images/logo/logo_dark.png";
      dropdown.classList.remove("text-black");
      dropdown.classList.add("text-white");
    }
  };
})();

const Header = () => {
  useEffect(() => {
    let navbarToggler = document.querySelector(".navbar-toggler");
    const navbarCollapse = document.querySelector(".navbar-collapse");

    const dropdown = document.querySelector(".dropdown-target");

    document.querySelectorAll(".ud-menu-scroll").forEach((e) =>
      e.addEventListener("click", () => {
        navbarToggler.classList.remove("active");
        navbarCollapse.classList.remove("show");
      })
    );
    navbarToggler.addEventListener("click", function () {
      navbarToggler.classList.toggle("active");
      navbarCollapse.classList.toggle("show");
    });

    if(navbarCollapse.classList.contains("show")){
      dropdown.classList.remove("text-white");
      dropdown.classList.add("text-black");
    }
    else{
      dropdown.classList.remove("text-black");
      dropdown.classList.add("text-white");
    }

    const submenuButton = document.querySelectorAll(".nav-item-has-children");
    submenuButton.forEach((elem) => {
      elem.querySelector("a").addEventListener("click", () => {
        elem.querySelector(".ud-submenu").classList.toggle("show");
      });
    });
  },[])
  return (
    <header className="ud-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" href="/">
                <img src="assets/images/logo/logo_dark.png" alt="DataMapper Company Logo" />
              </a>
              <button className="navbar-toggler">
                <span className="toggler-icon"> </span>
                <span className="toggler-icon"> </span>
                <span className="toggler-icon"> </span>
              </button>

              <div className="navbar-collapse">
                <ul id="nav" className="navbar-nav mx-auto">
                  <li className="nav-item">
                    <a className="ud-menu-scroll" href="/">Home</a>
                  </li>
                  <li className="nav-item">
                    <HashLink to="/#about" className='ud-menu-scroll'>About</HashLink>
                  </li>
                  <li className="nav-item services-dropdown">
                      <a className="dropdown-toggle ud-menu-scroll dropdown-target text-white" href="#services" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Services
                      </a>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="/cloud-operations">Cloud Operations</a></li>
                        <li><a className="dropdown-item my-2" href="/programming-support">Programming Support</a></li>
                        <li><a className="dropdown-item" href="/gis">GeoSpatial Data (GIS)</a></li>
                      </ul>
                  </li>
                  <li className="nav-item services-menu d-none">
                    <HashLink to="/cloud-operations" className='ud-menu-scroll'>Cloud Operations</HashLink>
                  </li>
                  <li className="nav-item services-menu d-none">
                    <HashLink to="/programming-support" className='ud-menu-scroll'>Programming Support</HashLink>
                  </li>
                  <li className="nav-item services-menu d-none">
                    <HashLink to="/gis" className='ud-menu-scroll'>GeoSpatial Data (GIS)</HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink to="/news" className='ud-menu-scroll'>News</HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink to="/#faq" className='ud-menu-scroll'>FAQ</HashLink>
                  </li>
                </ul>
              </div>

              <div className="navbar-btn d-none d-sm-inline-block">
                <HashLink to="/#contact" className='ud-main-btn ud-white-btn'>Contact Us</HashLink>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
