import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const ArticleOne = () => {
  useEffect(()=>{
    document.title="DataMapper - The Next Generation of Data Management Powered by Generative AI"
    let descMeta=document.querySelector("meta[name='description']")
    descMeta.setAttribute("content", 'Informatica, a well-known provider of end-to-end data management solutions, has introduced Claire GPT, a generative AI tool to simplify various data handling aspects.')
    },[])
  return (
    <div>
        <Header />
        <div className=''>
          <section className="ud-hero" id="home">
            <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                      <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
                        <h1 className="ud-hero-title pb-5">
                          The Next Generation of Data Management Powered by Generative AI
                        </h1>
                      </div>
                  </div>
                </div>
            </div>
        </section>
        <div className='container m-5 py-5 article-view'>
          <p className="lead">
            Informatica, a well-known provider of end-to-end data management solutions, has introduced Claire GPT, a generative AI 
            tool to simplify various data handling aspects. <br /><br /> 
            
            The offering, which was announced at the company's annual conference in 
            Las Vegas, allows enterprise users to ingest, process, manage, and analyze data using simple natural language prompts. 
            It will be integrated with Informatica's Intelligent Data Management Cloud (IDMC) and will begin to roll out in the 
            second half of 2023. <br /><br />

            The news comes as leading industry vendors, including many in the data space, continue to look at large language models
             (LLMs) as a way to make their products accessible to a broader spectrum of users.
          </p> <br /><br />
          <h3 className='fw-bold'>Interact with data via natural language</h3>
          <p className="lead">
            Effective data management is essential for business success, but given the tsunami of data that enterprises have, manual 
            approaches to managing data are no longer relevant. They take a lot of time, resources and effort. Plus, not every
            individual within the organization has the technical know-how for the job. <br /><br />

            Claire GPT from Informatica aims to address this gap with a text-to-IDMC interface where users can enter simple natural 
            language prompts to discover, interact with and manage their data assets. <br /><br />

            While the solution is yet to roll out widely, the company says it will support multiple jobs within the IDMC platform, 
            including data discovery, data pipeline creation and editing, metadata exploration, data quality and relationships 
            exploration, and data quality rule generation.  <br /><br />

            “It leverages a multi-LLM architecture, using public LLMs for non-sensitive queries (like intent classification, 
            where we use LLMs to identify the user intent — metadata exploration, data exploration, pipeline creation, etc.) and 
            fine-tuned Informatica-hosted LLMs that generate data management artifacts,” Amit Walia, CEO of Informatica, told 
            VentureBeat. He claims that the solution can help experienced data users, such as engineers, analysts and scientists, 
            realize up to an 80% reduction in time spent on key data management tasks.
          </p> <br /><br />
          <h3 className="fw-bold">Pairing with Claire</h3>
          <p className="lead">
            Informatica has designed the new conversational experience of data management by pairing its enterprise-scale AI engine 
            Claire with GPT capabilities. Claire processes 54 trillion transactions on a monthly basis, which ensures that the answers
            produced by the chatbot are grounded in reality and not hallucinating. <br /><br />

            “Ask ChatGPT to help you design and script a data pipeline, and it will. But that pipeline might not work. The problem 
            is that LLMs in themselves lack any semblance of governance. They are black boxes that dodge questions about lineage. 
            They emit errors and make things up. So, to really capture the productivity benefits of LLMs in a consistent way, you 
            must put [them] in a governed setting. Pairing GPT capabilities with Informatica’s Claire platform creates the 
            possibility that data teams can improve productivity with AI while maintaining governance and control,” Kevin Petrie, 
            vice president of research at Eckerson Group, told VentureBeat. <br /><br />

            Notably, Informatica is not the only player leveraging generative AI in such a way. Salesforce recently launched
            SlackGPT, combining Slack’s internal knowledge with LLMs, while observability major New Relic has launched Grok,
            an AI assistant for monitoring software for performance issues and fixing them.
          </p> <br /><br />
          <h3 className="fw-bold">What’s more at Informatica World?</h3>
          <p className="lead">
            Along with Claire GPT, Informatica also debuted new Claire-driven data management capabilities, including inferred data 
            lineage, autogenerated classifications, multicolumn completeness analysis and automapping. <br /><br />

            The company also took to the stage to announce IDMC for Environmental, Social and Governance, as well as “Cloud Data 
            Integration for PowerCenter (CDI-PC)” to help customers migrate on-premises PowerCenter assets to IDMC. With the CDI, 
            Informatica claims, enterprises will be able to move to the cloud up to six times faster, reuse 100% of PowerCenter
            artifacts and assets in the cloud, and realize anticipated cost savings of up to 20 times. <br /><br />

            Claire GPT is currently in the private preview stage and is expected to see a wider rollout in the second half of 2023. 
            The same goes for CDI-PC.
          </p>
        </div>
        </div>
        <Footer />
    </div>
  )
}

export default ArticleOne
