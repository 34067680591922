import Header from '../components/Header'
import Footer from '../components/Footer'
import { useEffect } from 'react'

const GIS = () => {
  useEffect(()=>{
    document.title="DataMapper - GIS Services"
    let descMeta=document.querySelector("meta[name='description']")
    descMeta.setAttribute("content", 'See our geospatial services, from WebGIS Development to spatial data operations.')
    },[])
  return (
    <div>
      <Header />
      <section className="ud-hero" id="home">
        <div className="container">
            <div className="row">
            <div className="col-lg-12">
                <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
                <h1 className="ud-hero-title pb-5">
                Geospatial Data Services
                </h1>
                </div>
            </div>
            </div>
        </div>
    </section>
      <div className='container m-5 pt-5 article-view'>
            <div className="my-5">
              <div className="row">
                <div className="col-12">
                <div className="container px-4 pb-5" id="icon-grid">
                  <h2 className="pb-2"><i class="fa-solid fa-map info_icon"></i>Bespoke Mapping</h2>
                  <p className="lead py-2">
                    Our Bespoke Map Creating Service offers customized and tailor-made maps to cater to your specific needs. 
                    Whether you require maps for business, tourism, education, or personal use, our team of expert cartographers
                    and designers will work closely with you to create visually stunning and highly informative maps. Here are 
                    the key features and benefits of our service
                  </p>
                  <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 g-5 py-5">
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Customization</h3>
                        <p>
                          We understand that every map requirement is unique. Our service allows you to customize every aspect of the map, including content, layout, colors, and styles.
                          You can choose the area of interest, level of detail, and specific features you want to include on the map.
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Design and Aesthetics</h3>
                        <p>
                          Our team of skilled cartographers and designers will create visually appealing maps that align with your branding guidelines and project objectives.
                          We pay attention to typography, color schemes, and layout to ensure the map is visually engaging and easy to understand.
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Data Integration</h3>
                        <p>
                          We can integrate your own data or datasets from various sources into the map. This includes adding markers, 
                          labels, boundaries, routes, or any other spatial data that is relevant to your project.
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Multiple Map Types</h3>
                        <p>
                          We offer a wide range of map types to suit different purposes, including thematic maps, topographic maps, street maps, campus maps, tourist maps, and more.
                          Each map type is designed to effectively convey specific information and meet your intended use.
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Integration with Digital Platforms</h3>
                        <p>
                          We can create maps that are compatible with digital platforms and online applications.
                          This includes creating interactive maps with clickable markers, zooming capabilities, and embedded information.
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Scale and Detail</h3>
                        <p>
                          We can create maps at various scales, from small neighbourhood maps to large-scale city or regional maps.
                          Our team ensures that the map's level of detail is appropriate for the target audience and the purpose of the map.
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Expert Guidance</h3>
                        <p>
                          Our team of cartographic experts will guide you throughout the map creation process, offering their expertise and insights to ensure the final product meets your expectations.
                          We provide recommendations on map elements, data representation, and design choices based on your project's objectives.
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Printing and Publishing</h3>
                        <p>
                          We provide high-quality printing services to ensure your maps look professional and can be used for physical distribution.
                          Additionally, we can assist with digital publishing, allowing you to share the maps online or embed them on your website.
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Timely Delivery</h3>
                        <p>
                          We understand the importance of timely delivery. Our team works efficiently to create and deliver your custom maps within the agreed-upon timeframe.
                          Whether you need maps for marketing, navigation, educational purposes, or any other application.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="container px-4 py-3" id="icon-grid">
                    <h2 className="pb-2"><i class="fa-solid fa-map info_icon"></i>Remote Sensing Services</h2>
                    <p className="lead py-2">
                      Our GIS Remote Sensing Services offer advanced solutions for harnessing the power of remote sensing data in 
                      Geographic Information Systems (GIS). By combining remote sensing technology with GIS analysis capabilities, 
                      we provide valuable insights and actionable information for a wide range of applications. Here are the key areas
                      covered by our services
                    </p>
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 g-5 py-5">
                      <div className="col d-flex align-items-start">
                        <div>
                          <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Data Acquisition and Processing</h3>
                          <p>
                            We acquire remote sensing data from various sources, including satellite imagery, aerial photography, LiDAR (Light Detection and Ranging), and hyperspectral sensors.
                            Our team performs pre-processing and image enhancement techniques to ensure high-quality and accurate data for further analysis.
                          </p>
                        </div>
                      </div>
                      <div className="col d-flex align-items-start">
                        <div>
                          <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Image Classification and Feature Extraction</h3>
                          <p>
                            We employ advanced image Classification techniques, such as supervised and unsupervised Classification, to extract land cover and land use information from remote sensing data.
                            Our experts identify and map various features, including vegetation, water bodies, urban areas, agricultural fields, and natural resources.
                          </p>
                        </div>
                      </div>
                      <div className="col d-flex align-items-start">
                        <div>
                          <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Change Detection and Monitoring</h3>
                          <p>
                            We utilize remote sensing data from different time periods to detect and monitor changes in land cover, land use, and other features.
                            Our team performs change detection analysis to identify areas of deforestation, urban expansion, environmental degradation, and other significant changes over time.
                          </p>
                        </div>
                      </div>
                      <div className="col d-flex align-items-start">
                        <div>
                          <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Terrain and Elevation Analysis</h3>
                          <p>
                            We extract digital elevation models (DEMs) from remote sensing data to analyse terrain characteristics, including slope, aspect, and elevation.
                            Our experts generate contour lines, perform viewshed analysis, and derive other terrain-related information for various applications, such as flood modelling, site selection, and infrastructure planning.
                          </p>
                        </div>
                      </div>
                      <div className="col d-flex align-items-start">
                        <div>
                          <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Vegetation Index and Crop Monitoring</h3>
                          <p>
                            We calculate vegetation indices, such as NDVI (Normalized Difference Vegetation Index), to assess vegetation health, biomass, and productivity.
                            Our team provides crop monitoring services, including crop type mapping, yield estimation, and anomaly detection, to support precision agriculture and food security initiatives.
                          </p>
                        </div>
                      </div>
                      <div className="col d-flex align-items-start">
                        <div>
                          <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Environmental Impact Assessment</h3>
                          <p>
                            We use remote sensing data to assess and monitor environmental changes, such as deforestation, pollution, land degradation, and natural disasters.
                            Our experts conduct environmental impact assessments and provide actionable insights for sustainable resource management and conservation efforts.
                          </p>
                        </div>
                      </div>
                      <div className="col d-flex align-items-start">
                        <div>
                          <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Urban Planning and Infrastructure Development</h3>
                          <p>
                            We utilize remote sensing data to support urban planning and infrastructure development projects.
                            Our team analyses urban growth patterns, land suitability, transportation networks, and other spatial factors to assist in efficient city planning and infrastructure decision-making.
                          </p>
                        </div>
                      </div>
                      <div className="col d-flex align-items-start">
                        <div>
                          <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Disaster Management and Emergency Response</h3>
                          <p>
                            We leverage remote sensing data for disaster management and emergency response activities.
                            Our experts generate damage assessment maps, flood extent maps, fire risk maps, and other critical information to aid in disaster response and mitigation efforts.
                          </p>
                        </div>
                      </div>
                      <div className="col d-flex align-items-start">
                        <div>
                          <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Integration with GIS and Visualization</h3>
                          <p>
                            We integrate remote sensing data into GIS platforms for spatial analysis, data visualization, and decision support.
                            Our team creates visually appealing and interactive maps, overlays remote sensing data with other geographic datasets, and develops custom GIS applications for easy data exploration.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="pb-2"><i class="fa-solid fa-computer info_icon"></i>Consultancy and Training</h2>
              <p className='lead my-2'>
                We offer consultancy services, providing expert advice on remote sensing data utilization, analysis techniques, and project-specific requirements.
                Our training programs and workshops help organizations develop in-house remote sensing capabilities and enhance their GIS workflows.
                By leveraging our GIS Remote Sensing Services, organizations can gain valuable insights into the Earth's surface and make informed decisions for a wide range of applications, including 
                environmental management, urban planning, agriculture, disaster management, and more. Contact us today to discuss your remote sensing needs and let our experts assist you in harnessing 
                the power of remote sensing data for your projects.
              </p>
              <h2 className="pb-2 mt-5"><i class="fa-solid fa-map-location-dot info_icon"></i>WebGIS Development</h2>
              <p className='lead my-2'>
                We develop interactive web-based and mobile GIS applications to facilitate data sharing, collaboration, and decision-making.
                Our team ensures seamless integration with existing systems and provides user-friendly interfaces for easy navigation and data exploration.
              </p>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default GIS
