import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const ProgrammingSupport = () => {
  useEffect(()=>{
      document.title="DataMapper - Programming Support Services"
      let descMeta=document.querySelector("meta[name='description']")
      descMeta.setAttribute("content", 'See our programming support services, from UX & UI Design to data visualization.')
  },[])
    return (
      <div>
        <Header />
        <section className="ud-hero" id="home">
          <div className="container">
              <div className="row">
              <div className="col-lg-12">
                  <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
                  <h1 className="ud-hero-title pb-5">
                  Programming Support
                  </h1>
                  </div>
              </div>
              </div>
          </div>
      </section>
        <div className='container m-5 pt-5 article-view'>
          <div className="my-5">
            <div className="row">
              <div className="col-12">
                <div className="container px-4 pb-5" id="icon-grid">
                  <h2 className="pb-2"><i class="fa-solid fa-code info_icon"></i>Systems Development</h2>
                  <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 g-5 py-5">
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">UI &amp; UX Design</h3>
                        <p>
                          Creating a specifc and professional design strategy for your business can help to seperate you from the crowd and give a
                          more premium feel to your business. With bespoke colour palettes and typography, keeping a consistant look and feel across
                          your company is key. At DataMapper, we develop custom interfaces and user experiences for our clients to ensure their customers
                          are given the best experience.
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Application Development</h3>
                        <p>
                          At DataMapper, we offer tailored application development to our clients, from small static web pages to full
                          scale dynamic web applications. We can develop websites to suit your requirements and scale to your business 
                          needs with ease. If required we can establish and maintain cloud databases to store data your application may
                          require and create visulization tools within your website to provide insight on this data. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="container px-4 pb-5" id="icon-grid">
                  <h2 className="pb-2"><i class="fa-solid fa-wrench info_icon"></i>Systems Upkeep</h2>
                  <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 g-5 py-5">
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Data Visualization</h3>
                        <p>
                          Some businesses require small visual demonstrations of their data, other need larger dashboard applications to
                          view complex data and filter as required. At DataMapper we can provide scripts to show your data in small applications
                          and file formats as well as develop bespoke applications to view and manipulate your data.
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Code Maintenance</h3>
                        <p>
                          Whether you have an existing application or a bespoke project from DataMapper, we offer our code maintenance 
                          services to regularly ensure the efficency and efficacy of the codebase, implementing updates where necessary.
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-start">
                      <div>
                        <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Database Administration</h3>
                        <p>
                          Handling data is the specialty of DataMapper, we can setup, maintain and optimise your databases using modern
                          technologies and cutting edge artificial intelligence. We offer our database administration to ensure that
                          your database runs fast, is maintained and optimised.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              </div>
          </div>
          <Footer />
      </div>
    )
  }

export default ProgrammingSupport
