import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import GIS from './pages/GIS';
import CloudOperations from './pages/CloudOperations';
import ProgrammingSupport from './pages/ProgrammingSupport';
import News from './pages/News';
import ArticleOne from './articles/ArticleOne';
import ArticleTwo from './articles/ArticleTwo';
import ArticleThree from './articles/ArticleThree';
import ArticleFour from './articles/ArticleFour';
import ArticleFive from './articles/ArticleFive';
import ArticleSix from './articles/ArticleSix';
import ArticleSeven from './articles/ArticleSeven';
import ArticleEight from './articles/ArticleEight';
import ArticleNine from './articles/ArticleNine';
import ArticleTen from './articles/ArticleTen';
import ArticleEleven from './articles/ArticleEleven';
import ArticleTwelve from './articles/ArticleTwelve';
import ArticleThirteen from './articles/ArticleThirteen';
import ArticleFourteen from './articles/ArticleFourteen';
import ArticleFifteen from './articles/ArticleFifteen';
import ArticleSixteen from './articles/ArticleSixteen';
import ArticleSeventeen from './articles/ArticleSeventeen';
import ScrollToTop from './components/ScrollToTop';

function App() {
     return (
          <BrowserRouter>
               <ScrollToTop>
                    <Routes>
                         <Route exact path="/" element={<Home/>} />
                         <Route path="/cloud-operations" element={<CloudOperations />} />
                         <Route path="/programming-support" element={<ProgrammingSupport />} />
                         <Route path="/gis" element={<GIS />} />
                         <Route path="/news" element={<News />} />
                         <Route path="/404" element={<NotFound />} />
                         <Route path="*" element={<Navigate to="/404" />} />

                         {/* Articles */}
                         <Route path="/the-next-generation-of-data-management-powered-by-generative-ai" element={<ArticleOne />} />
                         <Route path="/vmware-unveils-cross-cloud-managed-services" element={<ArticleTwo />} />
                         <Route path="/automatic-classification-harnessing-point-cloud-data-for-more-effective-use-with-ai" element={<ArticleThree />} />
                         <Route path="/gis-portal-enhances-collaboration-for-uk-nuclear-power-station" element={<ArticleFour />} />
                         <Route path="/tibco-scales-data-analytics-and-visualization-with-an-eye-on-ai" element={<ArticleFive />} />
                         <Route path="/the-gravitational-pull-of-data" element={<ArticleSix />} />
                         <Route path="/the-game-changing-potential-of-ai-powered-geospatial-analysis-in-shaping-and-preserving-our-world" element={<ArticleSeven />} />
                         <Route path="/ai-revolutionizing-data-management" element={<ArticleEight />} />
                         <Route path="/harnessing-cloud-computing-and-advanced-technologies-for-effective-data-management-in-the-modern-world" element={<ArticleNine />} />
                         <Route path="/leveraging-vmware-and-hyper-v-technology-to-enhance-data-management" element={<ArticleTen />} />
                         <Route path="/optimizing-efficiency-and-enhancing-security-through-ai-driven-solutions" element={<ArticleEleven />} />
                         <Route path="/unlocking-the-power-of-spatial-insights-for-businesses" element={<ArticleTwelve />} />
                         <Route path="/why-companies-should-opt-for-data-management-third-party-providers" element={<ArticleThirteen />} />
                         <Route path="/the-ai-powered-tool-revolutionizing-climate-action" element={<ArticleFourteen />} />
                         <Route path="/revolutionizing-data-management-in-gis-with-ai" element={<ArticleFifteen />} />
                         <Route path="/transforming-businesses-through-visual-insights" element={<ArticleSixteen />} />
                         <Route path="/spatial-data-visualization-unlocking-insights-through-visual-representation" element={<ArticleSeventeen />} />
                    </Routes>
               </ScrollToTop>
          </BrowserRouter>
     );
}
export default App;