import Header from '../components/Header'
import Footer from '../components/Footer'
import { useEffect } from 'react'

const CloudOperations = () => {
    useEffect(()=>{
        document.title="DataMapper - Cloud Operations Services"
        let descMeta=document.querySelector("meta[name='description']")
        descMeta.setAttribute("content", 'See our cloud operations services, from server & NAS management to Hyper-V / VM Support.')
    },[])
  return (
    <div>
      <Header />
      <section className="ud-hero" id="home">
        <div className="container">
            <div className="row">
            <div className="col-lg-12">
                <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
                <h1 className="ud-hero-title pb-5">
                    Cloud Operations Services
                </h1>
                </div>
            </div>
            </div>
        </div>
    </section>
    <div className='container m-5 pt-5 article-view'>
        <div className='my-5'>
          <div className="row">
              <div className="col-12">
              <div className="container px-4 pb-5" id="icon-grid">
                <h2 className="pb-2"><i class="fa-solid fa-boxes-stacked info_icon"></i>Management</h2>
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 g-5 py-5">
                  <div className="col d-flex align-items-start">
                    <div>
                      <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Data Management</h3>
                      <p>
                          On-site solutions for managing data are a thing of the past, the massive costs of maintaining servers and all
                          accompanying infrastrucutre as well as hiring staff to work with these systems can be a strain on businesses of all
                          scales. At DataMapper, we utlize modern systems such as cloud storage and AI to store your data and 
                          maintain your information in a secure and cost effective way. With the assistance of artificial intelligence,
                          we can optimize how we store and manage your data to ensure the highest efficiency and lowest costs.
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex align-items-start">
                    <div>
                      <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Server &amp; NAS Management</h3>
                      <p>
                          Setting up and running servers can be a difficult task, for smaller businesses without a dedicated IT department,
                          this can be problematic and expensive. That's where DataMapper can assist, our team can establish and maintain
                          servers for your business from small scale to enterprise level. With professional setup and constant support, we
                          ensure our clients are satisfied and running on high-quality and reliable systems.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
              <div className="container px-4 pb-5" id="icon-grid">
                <h2 className="pb-2"><i class="fa-solid fa-headset info_icon"></i>Support</h2>
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 g-5 py-5">
                  <div className="col d-flex align-items-start">
                    <div>
                      <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Hyper-V / VM Support</h3>
                      <p>
                        For our clients that require more advanced systems, we offer cloud based and virutal solutions to managing data,
                        this allows costs to be reduced through saving physical space as well as reducing management and hardware costs.
                        Using Hyper-V's can assist more IT focused businesses through reducing hardware costs and upkeep expenses as well as
                        allowing for more flexibility with tesing software across multiple operating systems. At DataMapper we offer these
                        systems as a service, handling the setup and management while ensuring your business is supported using these technologies.
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex align-items-start">
                    <div>
                      <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Technical Remote Support</h3>
                      <p>
                        With all the infrastrucutre needed to store and maintain data, we understand that your business may need support 
                        with understanding and using newer systems, our support team is always available to assist with any queries our
                        clients may have and solve problems your business may be facing, with a priority of minimal downtime and customer
                        satisfaction, we strive to resolve any issues with haste.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
        </div>
    </div>
    <Footer />
    </div>
  )
}

export default CloudOperations
