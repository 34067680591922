import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const ArticleThree = () => {
  useEffect(()=>{
    document.title="DataMapper - Automatic Classification: harnessing point cloud data for more effective use with AI"
    let descMeta=document.querySelector("meta[name='description']")
    descMeta.setAttribute("content", 'Point clouds contain huge amounts of spatial information, but unless that information is processed and labelled, it can’t be effectively used or even understood.')
    },[])
  return (
    <div>
        <Header />
        <div className=''>
          <section className="ud-hero" id="home">
            <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                      <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
                        <h1 className="ud-hero-title pb-5">
                            Automatic Classification: harnessing point cloud data for more effective use with AI
                        </h1>
                      </div>
                  </div>
                </div>
            </div>
        </section>
        <div className='container m-5 py-5 article-view'>
          <p className="lead">
            Point clouds contain huge amounts of spatial information, but unless that information is processed and labelled, it 
            can’t be effectively used or even understood. Applying object recognition to point clouds data classification is a 
            powerful first step towards harnessing point cloud data for more effective usage. <br /><br />

            A big trend impacting the utility of reality capture data is the application of Artificial Intelligence/Machine 
            Learning (AI/ML) algorithms to detect and extract objects and features from point clouds data sets — a process of 
            scan data classification that products like Vercator are at the forefront of. This automates the contextualisation 
            of data, making it far easier and more efficient to access the information required — opening new possibilities for 
            cost-effective applications of static, mobile scanning and other reality capture techniques and technologies.
          </p> <br /><br />
          <h3 className="fw-bold">Point Cloud Classification & Segmentation</h3>
          <p className="lead">
            The two terms are often used interchangeably however a general definition of each term usually separates them as: 
            segmentation being the action of separating the point cloud into different objects while classification applies the
            understanding of what those objects represent. <br /><br />

            Classification is a key part of automating the understanding of point clouds for various purposes. Once you can extract
            this information about what a 3D scene contains, then many downstream processes can be unlocked for value-added 
            automation. These include but not limited to: <br />
          </p>
          <ul>
            <li className="lead">
                - <b>Change detection –</b> knowledge about the objects in a scene allows for them to be compared to design models or as-builts.
            </li>
            <li className="lead">
                - <b>2D/3D modelling –</b> often touted as a ‘holy grail’ of automation, achieving automated modelling requires object identification for 
                efficient geometry processing.
            </li>
            <li className="lead">
                - <b>Scene filtering –</b> identification of transient objects which are often considered artefacts or ‘noise’ in scan data, allows for intelligent 
                filtering and cleaning.
            </li>
            <li className="lead">
                - <b>Robotic and autonomous system situational awareness –</b> extracting information about an autonomous system’s environment 
                can help it make smarter decisions and provide situational context; often the goal of AI techniques academically.
            </li>
          </ul> <br /><br />
          <h3 className="fw-bold">Automated Approaches</h3>
          <p className="lead">
            The ideas and approaches taken to aid the problem of object detection and classification are primarily for the 
            reconstruction of geometry from measured data. Both commercial and academic spheres of research have investigated 
            the automated reconstruction of geometry from point clouds and the first step in this segmentation. This has come to 
            the fore as building modelling has risen in prominence with the shift beyond 2D in construction, now requiring rich 3D
            parametric models, and the labour involved in that process. <br /><br />

            It could be considered that the research started in this domain with city modelling in the mid-1990s where the goal was
            to automate the reconstruction of urban 3D models from aerial photogrammetry. The aerial domain has seen a lot of 
            activity with many tools and developments target at this use case. The prominent point cloud format LAS standardises 
            and defines classifications for this domain.
          </p> <br /><br />
          <h3 className="fw-bold">Artificial Intelligence</h3>
          <p className="lead">
            There are a few commercial pieces of software that could be described as semi-automated whereby the user guides the 
            automation. However, the growth technology has been in services based around machine learning. <br /><br />

            The artificial intelligence technique machine learning has started emerging as a powerful tool for point cloud 
            classification. Rather than using approaches that define hard geometric rules for feature identification, ML can 
            be taught to find its own patterns for each object type from labelled input point data that is initially provided. 
            In so doing, this allows a more flexible and scalable way to do classification that can be tuned for different 
            purposes.
          </p> <br /><br />
          <h3 className="fw-bold">The Vercator Approach</h3>
          <p className="lead">
            At Vercator, we've pioneered cloud-based automation within point cloud processing and are applying the same technology 
            to improve classification. Our registration algorithms deliver a more robust registration and we have now turned our 
            expertise to creating a process that simplifies classification with our machine learning approach. <br /><br />

            Vercator brings to market fully automated, efficient segmentation & classification delivered from the cloud. The 
            Vercator approach uses automated machine learning to rapidly segment your single data set into a collection of 
            classified smaller data subsets, using one of our pre-generated models. <br /><br />

            The Vercator models currently available are:
          </p> <br />
          <ul>
            <li className="lead">
                - Interior building structures objects – e.g. floor, door, wall
            </li>
            <li className="lead">
                - Interior residential objects – e.g. table, chair
            </li>
            <li className="lead">
                - Highways objects – e.g. road surface, building, vegetation
            </li>
            <li className="lead">
                - Rail objects – e.g. rail, sleeper, track clip, bolt
            </li>
          </ul> <br /><br />
          <p className="lead">
            More will be added in future. If you have specific objects you need to extract that are not part of these existing models,
            then Vercator's team can work with you to deliver your optimum segmentation and classification results needed. All 
            classification models have to start from somewhere, however with specific input, Vercator really can deliver better 
            results, faster. <br /><br />

            Automation is on the horizon, and it stands to change classification, feature extraction and general point cloud data 
            handling forever. This is incredibly exciting as it stands to evolve the current data extraction processes. Improvements
            within cloud infrastructure have realised the potential of point clouds in the cloud. Harnessing limitless data and 
            the power of cloud computing the long-desired aim of automated point cloud processing can now be a reality.
          </p>
        </div>
        </div>
        <Footer />
    </div>
  )
}

export default ArticleThree
