import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import articleOne from '../article-images/articleOne.jpg'
import articleTwo from '../article-images/articleTwo.jpg'
import articleThree from '../article-images/articleThree.jpg'
import articleFour from '../article-images/articleFour.jpg'
import articleFive from '../article-images/articleFive.jpg'
import articleSix from '../article-images/articleSix.jpg'
import articleSeven from '../article-images/articleSeven.jpg'
import articleEight from '../article-images/articleEight.jpg'
import articleNine from '../article-images/articleNine.jpg'
import articleTen from '../article-images/articleTen.jpg'
import articleEleven from '../article-images/articleEleven.jpg'
import articleTwelve from '../article-images/articleTwelve.jpg'
import articleThirteen from '../article-images/articleThirteen.jpg'
import articleFourteen from '../article-images/articleFourteen.jpg'
import articleFifteen from '../article-images/articleFifteen.jpg'
import articleSixteen from '../article-images/articleSixteen.jpg'
import articleSeventeen from '../article-images/articleSeventeen.jpg'
import { Link } from 'react-router-dom';

const News = () => {
    useEffect(()=>{
        document.title="DataMapper - News"
        let descMeta=document.querySelector("meta[name='description']")
        descMeta.setAttribute("content", 'View the latest industry news in data management, visualization and GeoSpatial Mapping.')
        },[])
  return (
    <div>
        <Header />
        <div>
            <section className="ud-hero" id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
                                <h1 className="ud-hero-title pb-5">
                                    See our latest and archived news articles
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='container m-auto p-5'>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <Link to="/spatial-data-visualization-unlocking-insights-through-visual-representation" className='article_preview'>
                        <img src={articleSeventeen} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>Spatial Data Visualization: Unlocking Insights through Visual Representation</h4>
                                    <br />
                                    <p>27/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to="/transforming-businesses-through-visual-insights" className='article_preview'>
                        <img src={articleSixteen} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>Transforming Businesses through Visual Insights</h4>
                                    <br />
                                    <p>23/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to="/revolutionizing-data-management-in-gis-with-ai" className='article_preview'>
                        <img src={articleFifteen} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>Revolutionizing Data Management in GIS with AI</h4>
                                    <br />
                                    <p>22/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <Link to="/the-ai-powered-tool-revolutionizing-climate-action" className='article_preview'>
                        <img src={articleFourteen} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>The AI-Powered Tool Revolutionizing Climate Action</h4>
                                    <br />
                                    <p>20/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to="/why-companies-should-opt-for-data-management-third-party-providers" className='article_preview'>
                        <img src={articleThirteen} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>Why Companies Should Opt for Third-Party Data Management Providers</h4>
                                    <br />
                                    <p>19/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to="/unlocking-the-power-of-spatial-insights-for-businesses" className='article_preview'>
                        <img src={articleTwelve} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>Unlocking the Power of Spatial Insights for Businesses</h4>
                                    <br />
                                    <p>15/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <Link to="/optimizing-efficiency-and-enhancing-security-through-ai-driven-solutions" className='article_preview'>
                        <img src={articleEleven} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>Optimizing Efficiency and Enhancing Security through AI-driven Solutions</h4>
                                    <br />
                                    <p>14/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to="/leveraging-vmware-and-hyper-v-technology-to-enhance-data-management" className='article_preview'>
                        <img src={articleTen} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>Leveraging VMware and Hyper-V Technology to Enhance Data Management</h4>
                                    <br />
                                    <p>13/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to="/harnessing-cloud-computing-and-advanced-technologies-for-effective-data-management-in-the-modern-world" className='article_preview'>
                        <img src={articleNine} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>Harnessing Cloud Computing and Advanced Technologies for Effective Data Management in the Modern World</h4>
                                    <br />
                                    <p>11/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <Link to="/ai-revolutionizing-data-management" className='article_preview'>
                        <img src={articleEight} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>AI Revolutionizing Data Management</h4>
                                    <br />
                                    <p>08/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to="/the-game-changing-potential-of-ai-powered-geospatial-analysis-in-shaping-and-preserving-our-world" className='article_preview'>
                        <img src={articleSeven} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>The Game-Changing Potential of AI-Powered Geospatial Analysis in Shaping and Preserving our World</h4>
                                    <br />
                                    <p>06/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to="/the-gravitational-pull-of-data" className='article_preview'>
                        <img src={articleSix} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>The Gravitational Pull of Data: Navigating the Expanding Cloud Universe</h4>
                                    <br />
                                    <p>03/06/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <Link to="/the-next-generation-of-data-management-powered-by-generative-ai" className='article_preview'>
                        <img src={articleOne} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>The Next Generation of Data Management Powered by Generative AI</h4>
                                    <br />
                                    <p>09/05/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to="/vmware-unveils-cross-cloud-managed-services" className='article_preview'>
                        <img src={articleTwo} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>VMware Unveils Cross Cloud Managed Services</h4>
                                    <br />
                                    <p>04/05/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to="/tibco-scales-data-analytics-and-visualization-with-an-eye-on-ai" className='article_preview'>
                        <img src={articleFive} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>Tibco scales data analytics and visualization with an eye on AI</h4>
                                    <br />
                                    <p>14/03/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <Link to="/automatic-classification-harnessing-point-cloud-data-for-more-effective-use-with-ai" className='article_preview'>
                        <img src={articleThree} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>Automatic Classification: harnessing point cloud data for more effective use with AI</h4>
                                    <br />
                                    <p>10/05/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <Link to="/gis-portal-enhances-collaboration-for-uk-nuclear-power-station" className='article_preview'>
                        <img src={articleFour} alt='Article Thumbnail' className='rounded-3' />
                        <div className="ud-single-news wow fadeInUp article-card" data-wow-delay=".2s">
                            <div className="ud-news-info">
                                <div className="ud-news-meta">
                                    <h4>GIS Portal Enhances Collaboration for UK Nuclear Power Station</h4>
                                    <br />
                                    <p>24/02/2023</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default News
